<template>
  <h1>Beratungsschwerpunkte</h1>
  <div style="display: flex;">
    <div style="margin-right: 24px;">
      <h3>Klinik-Personal / Human Resources Management</h3>
      <ul>
        <li>Arbeitszeitmodelle</li>
        <li>Vergütungssysteme</li>
        <li>Personalbedarfsanalyse</li>
        <li>Recruiting Management</li>
        <li>Outplacement</li>
        <li>Personalentwicklung</li>
        <li>Mitarbeiter + Führungskräfte – Potenzialanalyse</li>
        <li>Mitarbeiter + Führungskräfte – Coaching</li>
        <li>Change- und Integrations Management</li>
        <li>Betriebsübergang</li>
      </ul>
    </div>
    <div>
      <h3>Klinik-Organisation, Unternehmensentwicklung und Strategie</h3>
      <ul>
        <li>Change-Management</li>
        <li>Potenzialanalyse/Evaluierung</li>
        <li>Prozessoptimierung</li>
        <li>Prozesskostenoptimierung</li>
        <li>Reorganisation</li>
        <li>Risikomanagement</li>
        <li>Controlling</li>
        <li>Outsourcing</li>
        <li>Qualitätsmanagement (KTQ)</li>
      </ul>

      <h3>Klinik-Umsetzungsbegleitung</h3>
      <ul>
        <li>Interimsmanagement</li>
        <li>Projektmanagement</li>
        <li>Controlling</li>
      </ul>
    </div>
  </div>
</template>
